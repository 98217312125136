<template>
  <nav id="navbar_main" class="mobile-offcanvas navbar navbar-expand-lg">
    <div class="offcanvas-header">
      <button class="btn-close float-end"></button>
      <h5 class="py-2 text-white">Navigation</h5>
    </div>
    <ul class="navbar-nav">
      <li class="nav-item active">
        <router-link class="nav-link" :to="{ name: 'home' }">Home </router-link>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle dropdown-toggle-nocaret" href="#" data-bs-toggle="dropdown">Categories <i class="bx bx-chevron-down"></i></a>
        <div class="dropdown-menu dropdown-large-menu">
          <div class="row">
            <div class="col-md-4">
              <h6 class="large-menu-title">Fashion</h6>
              <ul class="">
                <li><a href="#">Casual T-Shirts</a></li>
                <li><a href="#">Formal Shirts</a></li>
                <li><a href="#">Jackets</a></li>
                <li><a href="#">Jeans</a></li>
                <li><a href="#">Dresses</a></li>
                <li><a href="#">Sneakers</a></li>
                <li><a href="#">Belts</a></li>
                <li><a href="#">Sports Shoes</a></li>
              </ul>
            </div>
            <!-- end col-3 -->
            <div class="col-md-4">
              <h6 class="large-menu-title">Electronics</h6>
              <ul class="">
                <li><a href="#">Mobiles</a></li>
                <li><a href="#">Laptops</a></li>
                <li><a href="#">Macbook</a></li>
                <li><a href="#">Televisions</a></li>
                <li><a href="#">Lighting</a></li>
                <li><a href="#">Smart Watch</a></li>
                <li><a href="#">Galaxy Phones</a></li>
                <li><a href="#">PC Monitors</a></li>
              </ul>
            </div>
            <!-- end col-3 -->
            <div class="col-md-4">
              <div class="pramotion-banner1">
                <img src="assets/images/gallery/menu-img.jpg" class="img-fluid" alt="" />
              </div>
            </div>
            <!-- end col-3 -->
          </div>
          <!-- end row -->
        </div>
        <!-- dropdown-large.// -->
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle dropdown-toggle-nocaret" href="#" data-bs-toggle="dropdown">Shop <i class="bx bx-chevron-down"></i></a>
        <ul class="dropdown-menu">
          <li>
            <a class="dropdown-item dropdown-toggle dropdown-toggle-nocaret" href="#">Shop Layouts <i class="bx bx-chevron-right float-end"></i></a>
            <ul class="submenu dropdown-menu">
              <li>
                <a class="dropdown-item" href="shop-grid-left-sidebar.html">Shop Grid - Left Sidebar</a>
              </li>
              <li>
                <a class="dropdown-item" href="shop-grid-right-sidebar.html">Shop Grid - Right Sidebar</a>
              </li>
              <li>
                <a class="dropdown-item" href="shop-list-left-sidebar.html">Shop List - Left Sidebar</a>
              </li>
              <li>
                <a class="dropdown-item" href="shop-list-right-sidebar.html">Shop List - Right Sidebar</a>
              </li>
              <li>
                <a class="dropdown-item" href="shop-grid-filter-on-top.html">Shop Grid - Top Filter</a>
              </li>
              <li>
                <a class="dropdown-item" href="shop-list-filter-on-top.html">Shop List - Top Filter</a>
              </li>
            </ul>
          </li>
          <li>
            <a class="dropdown-item dropdown-toggle dropdown-toggle-nocaret" href="#">Shop Pages <i class="bx bx-chevron-right float-end"></i></a>
            <ul class="submenu dropdown-menu">
              <li>
                <a class="dropdown-item" href="shop-cart.html">Shop Cart</a>
              </li>
              <li>
                <a class="dropdown-item" href="shop-categories.html">Shop Categories</a>
              </li>
              <li>
                <a class="dropdown-item" href="checkout-details.html">Checkout Details</a>
              </li>
              <li>
                <a class="dropdown-item" href="checkout-shipping.html">Checkout Shipping</a>
              </li>
              <li>
                <a class="dropdown-item" href="checkout-payment.html">Checkout Payment</a>
              </li>
              <li>
                <a class="dropdown-item" href="checkout-review.html">Checkout Review</a>
              </li>
              <li>
                <a class="dropdown-item" href="checkout-complete.html">Checkout Complete</a>
              </li>
              <li>
                <a class="dropdown-item" href="order-tracking.html">Order Tracking</a>
              </li>
              <li>
                <a class="dropdown-item" href="product-comparison.html">Product Comparison</a>
              </li>
            </ul>
          </li>
          <li>
            <a class="dropdown-item" href="about-us.html">About Us</a>
          </li>
          <li>
            <a class="dropdown-item" href="contact-us.html">Contact Us</a>
          </li>
          <li>
            <a class="dropdown-item" href="authentication-signin.html">Sign In</a>
          </li>
          <li>
            <a class="dropdown-item" href="authentication-signup.html">Sign Up</a>
          </li>
          <li>
            <a class="dropdown-item" href="authentication-forgot-password.html">Forgot Password</a>
          </li>
        </ul>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'blog' }">Blog </router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'about-us' }">About Us </router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'contact-us' }">Contact Us </router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'shop-categories' }">Our Store</router-link>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle dropdown-toggle-nocaret" href="#" data-bs-toggle="dropdown">My Account <i class="bx bx-chevron-down"></i></a>
        <ul class="dropdown-menu">
          <li>
            <router-link class="dropdown-item" :to="{ name: 'account-dashboard' }">Dashboard</router-link>
          </li>
          <li>
            <router-link class="dropdown-item" :to="{ name: 'account-downloads' }">Downloads</router-link>
          </li>
          <li>
            <router-link class="dropdown-item" :to="{ name: 'account-orders' }">Orders</router-link>
          </li>
          <li>
            <router-link class="dropdown-item" :to="{ name: 'account-payment-methods' }">Payment Methods</router-link>
          </li>
          <li>
            <router-link class="dropdown-item" :to="{ name: 'account-user-details' }">User Details</router-link>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "NavbarView",
};
</script>

<style></style>

<template>
  <div class="header-wrapper bg-dark-1">
    <div class="top-menu border-bottom">
      <div class="container">
        <nav class="navbar navbar-expand">
          <div class="shiping-title text-uppercase font-13 text-white d-none d-sm-flex">Welcome to our eTrans store!</div>
          <ul class="navbar-nav ms-auto d-none d-lg-flex">
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'order-tracking' }">Track Order</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'about-us' }">About</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'shop-categories' }">Our Stores</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'blog' }">Blog</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'contact-us' }">Contact</router-link>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="javascript:;">Help & FAQs</a>
            </li>
          </ul>
          <ul class="navbar-nav">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">USD</a>
              <ul class="dropdown-menu dropdown-menu-lg-end">
                <li><a class="dropdown-item" href="#">USD</a></li>
                <li><a class="dropdown-item" href="#">EUR</a></li>
              </ul>
            </li>
            <!-- <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle dropdown-toggle-nocaret" href="#" data-bs-toggle="dropdown">
                <div class="lang d-flex gap-1">
                  <div><i class="flag-icon flag-icon-um"></i></div>
                  <div><span>ENG</span></div>
                </div>
              </a>
              <div class="dropdown-menu dropdown-menu-lg-end">
                <a class="dropdown-item d-flex allign-items-center" href="javascript:;"> <i class="flag-icon flag-icon-de me-2"></i><span>German</span> </a>
                <a class="dropdown-item d-flex allign-items-center" href="javascript:;"><i class="flag-icon flag-icon-fr me-2"></i><span>French</span></a>
                <a class="dropdown-item d-flex allign-items-center" href="javascript:;"><i class="flag-icon flag-icon-um me-2"></i><span>English</span></a>
                <a class="dropdown-item d-flex allign-items-center" href="javascript:;"><i class="flag-icon flag-icon-in me-2"></i><span>Hindi</span></a>
                <a class="dropdown-item d-flex allign-items-center" href="javascript:;"><i class="flag-icon flag-icon-cn me-2"></i><span>Chinese</span></a>
                <a class="dropdown-item d-flex allign-items-center" href="javascript:;"><i class="flag-icon flag-icon-ae me-2"></i><span>Arabic</span></a>
              </div>
            </li> -->
          </ul>
          <ul class="navbar-nav social-link ms-lg-2 ms-auto">
            <li class="nav-item">
              <a class="nav-link" href="javascript:;"><i class="bx bxl-facebook"></i></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="javascript:;"><i class="bx bxl-twitter"></i></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="javascript:;"><i class="bx bxl-linkedin"></i></a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="header-content pb-3 pb-md-0">
      <div class="container">
        <div class="row align-items-center">
          <div class="col col-md-auto">
            <div class="d-flex align-items-center">
              <div class="mobile-toggle-menu d-lg-none px-lg-2" data-trigger="#navbar_main">
                <i class="bx bx-menu"></i>
              </div>
              <div class="logo d-none d-lg-flex">
                <router-link :to="{ name: 'home' }">
                  <img src="assets/images/logo-icon.png" class="logo-icon" alt="" />
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-12 col-md order-4 order-md-2">
            <div class="input-group flex-nowrap px-xl-4">
              <input type="text" class="form-control w-100" placeholder="Search for Products" />
              <select class="form-select flex-shrink-0" aria-label="Default select example" style="width: 10.5rem">
                <option selected>All Categories</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
              <span class="input-group-text cursor-pointer"><i class="bx bx-search"></i></span>
            </div>
          </div>
          <div class="col col-md-auto order-3 d-none d-xl-flex align-items-center">
            <div class="fs-1 text-white">
              <i class="bx bx-headphone"></i>
            </div>
            <div class="ms-2">
              <p class="mb-0 font-13">CALL US NOW</p>
              <h5 class="mb-0">+011 5827918</h5>
            </div>
          </div>
          <div class="col col-md-auto order-2 order-md-4">
            <div class="top-cart-icons">
              <nav class="navbar navbar-expand">
                <ul class="navbar-nav ms-auto">
                  <li class="nav-item">
                    <a href="account-dashboard.html" class="nav-link cart-link"><i class="bx bx-user"></i></a>
                  </li>
                  <li class="nav-item">
                    <a href="wishlist.html" class="nav-link cart-link"><i class="bx bx-heart"></i></a>
                  </li>
                  <li class="nav-item dropdown dropdown-large">
                    <a href="#" class="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative cart-link" data-bs-toggle="dropdown">
                      <span class="alert-count">8</span>
                      <i class="bx bx-shopping-bag"></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-end">
                      <a href="javascript:;">
                        <div class="cart-header">
                          <p class="cart-header-title mb-0">8 ITEMS</p>
                          <p class="cart-header-clear ms-auto mb-0">VIEW CART</p>
                        </div>
                      </a>
                      <div class="cart-list">
                        <a class="dropdown-item" href="javascript:;">
                          <div class="d-flex align-items-center">
                            <div class="flex-grow-1">
                              <h6 class="cart-product-title">Men White T-Shirt</h6>
                              <p class="cart-product-price">1 X $29.00</p>
                            </div>
                            <div class="position-relative">
                              <div class="cart-product-cancel position-absolute">
                                <i class="bx bx-x"></i>
                              </div>
                              <div class="cart-product">
                                <img src="assets/images/products/01.png" class="" alt="product image" />
                              </div>
                            </div>
                          </div>
                        </a>
                        <a class="dropdown-item" href="javascript:;">
                          <div class="d-flex align-items-center">
                            <div class="flex-grow-1">
                              <h6 class="cart-product-title">Puma Sports Shoes</h6>
                              <p class="cart-product-price">1 X $29.00</p>
                            </div>
                            <div class="position-relative">
                              <div class="cart-product-cancel position-absolute">
                                <i class="bx bx-x"></i>
                              </div>
                              <div class="cart-product">
                                <img src="assets/images/products/05.png" class="" alt="product image" />
                              </div>
                            </div>
                          </div>
                        </a>
                        <a class="dropdown-item" href="javascript:;">
                          <div class="d-flex align-items-center">
                            <div class="flex-grow-1">
                              <h6 class="cart-product-title">Women Red Sneakers</h6>
                              <p class="cart-product-price">1 X $29.00</p>
                            </div>
                            <div class="position-relative">
                              <div class="cart-product-cancel position-absolute">
                                <i class="bx bx-x"></i>
                              </div>
                              <div class="cart-product">
                                <img src="assets/images/products/17.png" class="" alt="product image" />
                              </div>
                            </div>
                          </div>
                        </a>
                        <a class="dropdown-item" href="javascript:;">
                          <div class="d-flex align-items-center">
                            <div class="flex-grow-1">
                              <h6 class="cart-product-title">Black Headphone</h6>
                              <p class="cart-product-price">1 X $29.00</p>
                            </div>
                            <div class="position-relative">
                              <div class="cart-product-cancel position-absolute">
                                <i class="bx bx-x"></i>
                              </div>
                              <div class="cart-product">
                                <img src="assets/images/products/10.png" class="" alt="product image" />
                              </div>
                            </div>
                          </div>
                        </a>
                        <a class="dropdown-item" href="javascript:;">
                          <div class="d-flex align-items-center">
                            <div class="flex-grow-1">
                              <h6 class="cart-product-title">Blue Girl Shoes</h6>
                              <p class="cart-product-price">1 X $29.00</p>
                            </div>
                            <div class="position-relative">
                              <div class="cart-product-cancel position-absolute">
                                <i class="bx bx-x"></i>
                              </div>
                              <div class="cart-product">
                                <img src="assets/images/products/08.png" class="" alt="product image" />
                              </div>
                            </div>
                          </div>
                        </a>
                        <a class="dropdown-item" href="javascript:;">
                          <div class="d-flex align-items-center">
                            <div class="flex-grow-1">
                              <h6 class="cart-product-title">Men Leather Belt</h6>
                              <p class="cart-product-price">1 X $29.00</p>
                            </div>
                            <div class="position-relative">
                              <div class="cart-product-cancel position-absolute">
                                <i class="bx bx-x"></i>
                              </div>
                              <div class="cart-product">
                                <img src="assets/images/products/18.png" class="" alt="product image" />
                              </div>
                            </div>
                          </div>
                        </a>
                        <a class="dropdown-item" href="javascript:;">
                          <div class="d-flex align-items-center">
                            <div class="flex-grow-1">
                              <h6 class="cart-product-title">Men Yellow T-Shirt</h6>
                              <p class="cart-product-price">1 X $29.00</p>
                            </div>
                            <div class="position-relative">
                              <div class="cart-product-cancel position-absolute">
                                <i class="bx bx-x"></i>
                              </div>
                              <div class="cart-product">
                                <img src="assets/images/products/04.png" class="" alt="product image" />
                              </div>
                            </div>
                          </div>
                        </a>
                        <a class="dropdown-item" href="javascript:;">
                          <div class="d-flex align-items-center">
                            <div class="flex-grow-1">
                              <h6 class="cart-product-title">Pool Charir</h6>
                              <p class="cart-product-price">1 X $29.00</p>
                            </div>
                            <div class="position-relative">
                              <div class="cart-product-cancel position-absolute">
                                <i class="bx bx-x"></i>
                              </div>
                              <div class="cart-product">
                                <img src="assets/images/products/16.png" class="" alt="product image" />
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                      <a href="javascript:;">
                        <div class="text-center cart-footer d-flex align-items-center">
                          <h5 class="mb-0">TOTAL</h5>
                          <h5 class="mb-0 ms-auto">$189.00</h5>
                        </div>
                      </a>
                      <div class="d-grid p-3 border-top">
                        <a href="javascript:;" class="btn btn-light btn-ecomm">CHECKOUT</a>
                      </div>
                    </div>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <!--end row-->
      </div>
    </div>
    <div class="primary-menu border-top">
      <div class="container">
        <navbar></navbar>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "./NavbarView.vue";
export default {
  name: "HeaderView",
  components: { Navbar },
};
</script>

<style></style>

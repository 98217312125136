<template>
  <footer>
    <section class="py-4 bg-dark-1">
      <div class="container">
        <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-4">
          <div class="col">
            <div class="footer-section1 mb-3">
              <h6 class="mb-3 text-uppercase">Contact Info</h6>
              <div class="address mb-3">
                <p class="mb-0 text-uppercase text-white">Address</p>
                <p class="mb-0 font-12">House-2, Road-13, Block-C, Banasree, Rampura, Dhaka-1219</p>
              </div>
              <div class="phone mb-3">
                <p class="mb-0 text-uppercase text-white">Phone</p>
                <!-- <p class="mb-0 font-13">Toll Free (123) 472-796</p> -->
                <p class="mb-0 font-13">Mobile : +8801954 593051</p>
              </div>
              <div class="email mb-3">
                <p class="mb-0 text-uppercase text-white">Email</p>
                <p class="mb-0 font-13">aritechengineering@gmail.com</p>
              </div>
              <div class="working-days mb-3">
                <p class="mb-0 text-uppercase text-white">WORKING DAYS</p>
                <p class="mb-0 font-13">Sat - Thu / 9:30 AM - 6:30 PM</p>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="footer-section2 mb-3">
              <h6 class="mb-3 text-uppercase">Shop Categories</h6>
              <ul class="list-unstyled">
                <li class="mb-1">
                  <a href="javascript:;"><i class="bx bx-chevron-right"></i> Jeans</a>
                </li>
                <li class="mb-1">
                  <a href="javascript:;"><i class="bx bx-chevron-right"></i> T-Shirts</a>
                </li>
                <li class="mb-1">
                  <a href="javascript:;"><i class="bx bx-chevron-right"></i> Sports</a>
                </li>
                <li class="mb-1">
                  <a href="javascript:;"><i class="bx bx-chevron-right"></i> Shirts & Tops</a>
                </li>
                <li class="mb-1">
                  <a href="javascript:;"><i class="bx bx-chevron-right"></i> Clogs & Mules</a>
                </li>
                <li class="mb-1">
                  <a href="javascript:;"><i class="bx bx-chevron-right"></i> Sunglasses</a>
                </li>
                <li class="mb-1">
                  <a href="javascript:;"><i class="bx bx-chevron-right"></i> Bags & Wallets</a>
                </li>
                <li class="mb-1">
                  <a href="javascript:;"><i class="bx bx-chevron-right"></i> Sneakers & Athletic</a>
                </li>
                <li class="mb-1">
                  <a href="javascript:;"><i class="bx bx-chevron-right"></i> Electronis</a>
                </li>
                <li class="mb-1">
                  <a href="javascript:;"><i class="bx bx-chevron-right"></i> Furniture</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col">
            <div class="footer-section3 mb-3">
              <h6 class="mb-3 text-uppercase">Popular Tags</h6>
              <div class="tags-box">
                <a href="javascript:;" class="tag-link">Cloths</a>
                <a href="javascript:;" class="tag-link">Electronis</a>
                <a href="javascript:;" class="tag-link">Furniture</a>
                <a href="javascript:;" class="tag-link">Sports</a>
                <a href="javascript:;" class="tag-link">Men Wear</a>
                <a href="javascript:;" class="tag-link">Women Wear</a>
                <a href="javascript:;" class="tag-link">Laptops</a>
                <a href="javascript:;" class="tag-link">Formal Shirts</a>
                <a href="javascript:;" class="tag-link">Topwear</a>
                <a href="javascript:;" class="tag-link">Headphones</a>
                <a href="javascript:;" class="tag-link">Bottom Wear</a>
                <a href="javascript:;" class="tag-link">Bags</a>
                <a href="javascript:;" class="tag-link">Sofa</a>
                <a href="javascript:;" class="tag-link">Shoes</a>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="footer-section4 mb-3">
              <h6 class="mb-3 text-uppercase">Stay informed</h6>
              <div class="subscribe">
                <input type="text" class="form-control radius-30" placeholder="Enter Your Email" />
                <div class="mt-2 d-grid">
                  <a href="javascript:;" class="btn btn-white btn-ecomm radius-30">Subscribe</a>
                </div>
                <p class="mt-2 mb-0 font-13">Subscribe to our newsletter to receive early discount offers, updates and new products info.</p>
              </div>
              <div class="download-app mt-3">
                <h6 class="mb-3 text-uppercase">Download our app</h6>
                <div class="d-flex align-items-center gap-2">
                  <a href="javascript:;">
                    <img src="assets/images/icons/apple-store.png" class="" width="160" alt="" />
                  </a>
                  <a href="javascript:;">
                    <img src="assets/images/icons/play-store.png" class="" width="160" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end row-->
        <hr />
        <div class="row row-cols-1 row-cols-md-2 align-items-center">
          <div class="col">
            <p class="mb-0">Copyright © 2021. All right reserved.</p>
          </div>
          <div class="col text-end">
            <div class="payment-icon">
              <div class="row row-cols-auto g-2 justify-content-end">
                <div class="col">
                  <img src="assets/images/icons/visa.png" alt="" />
                </div>
                <div class="col">
                  <img src="assets/images/icons/paypal.png" alt="" />
                </div>
                <div class="col">
                  <img src="assets/images/icons/mastercard.png" alt="" />
                </div>
                <div class="col">
                  <img src="assets/images/icons/american-express.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end row-->
      </div>
    </section>
  </footer>
</template>

<script>
export default {
  name: "FooterView",
};
</script>

<style></style>

import { createRouter, createWebHistory } from "vue-router";
// import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "master" */ "../views/pages/HomeView.vue"),
    // component: HomeView
  },
  {
    path: "/order-tracking",
    name: "order-tracking",
    component: () => import(/* webpackChunkName: "about" */ "../views/pages/OrderTracking.vue"),
  },
  {
    path: "/about-us",
    name: "about-us",
    component: () => import(/* webpackChunkName: "about" */ "../views/pages/AboutUs.vue"),
  },
  {
    path: "/contact-us",
    name: "contact-us",
    component: () => import(/* webpackChunkName: "about" */ "../views/pages/ContactUs.vue"),
  },
  {
    path: "/blog",
    name: "blog",
    component: () => import(/* webpackChunkName: "about" */ "../views/pages/BlogVue.vue"),
  },
  {
    path: "/shop-categories",
    name: "shop-categories",
    component: () => import(/* webpackChunkName: "about" */ "../views/pages/ShopCategories.vue"),
  },
  {
    path: "/account-dashboard",
    name: "account-dashboard",
    component: () => import(/* webpackChunkName: "about" */ "../views/pages/AccountDashboard.vue"),
  },
  {
    path: "/account-orders",
    name: "account-orders",
    component: () => import(/* webpackChunkName: "about" */ "../views/pages/AccountOrders.vue"),
  },
  {
    path: "/account-downloads",
    name: "account-downloads",
    component: () => import("../views/pages/AccountDownloads.vue"),
  },
  {
    path: "/account-payment-methods",
    name: "account-payment-methods",
    component: () => import("../views/pages/AccountPaymentMethods.vue"),
  },
  {
    path: "/account-user-details",
    name: "account-user-details",
    component: () => import("../views/pages/AccountUserDetails.vue"),
  },
  {
    path: "/account-addresses",
    name: "account-addresses",
    component: () => import("../views/pages/AccountUserDetails.vue"),
  },
  {
    path: "/product-details",
    name: "product-details",
    component: () => import("../views/pages/ProductDetails.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
